/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Imports the icons */
/*@import '~css.gg/icons/all.css'; */

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

ion-modal {
  --border-radius: 10px;
}
ion-list.list-inset,
ion-list[ng-reflect-inset='true'] {
  border-radius: 10px !important;
}

.export-options-popover {
  --width: 450px;
}

lib-fulltext-counter.insideMenu {
  p {
    margin: 0px !important;
  }
}

.b_valid {
  border-right: var(--ion-color-success) solid 6px !important;
}

.b_invalid {
  border-right: var(--ion-color-danger) solid 6px !important;
}

mits-boilerplates.b_valid {
  border-right: none !important;

  ion-textarea {
    border-right: var(--ion-color-success) solid 6px;
  }
}
mits-boilerplates.b_invalid {
  border-right: none !important;

  ion-textarea {
    border-right: var(--ion-color-danger) solid 6px;
  }
}

mits-object-select.b_valid {
  border-right: none !important;

  ion-item {
    border-right: var(--ion-color-success) solid 6px;
  }
}
mits-object-select.b_invalid {
  border-right: none !important;

  ion-item {
    border-right: var(--ion-color-danger) solid 6px;
  }
}

mits-article-list.b_valid {
  border-right: none !important;

  .mitsArticleList {
    border-right: var(--ion-color-success) solid 6px !important;
  }
}
mits-article-list.b_invalid {
  border-right: none !important;

  .mitsArticleList {
    border-right: var(--ion-color-danger) solid 6px !important;
  }
}

mits-files-upload-select.b_valid {
  border-right: none !important;

  .mitsUploadSelectImagesWrapper {
    border-right: var(--ion-color-success) solid 6px !important;
  }
}
mits-files-upload-select.b_invalid {
  border-right: none !important;

  .mitsUploadSelectImagesWrapper {
    border-right: var(--ion-color-danger) solid 6px !important;
  }
}

ion-card {
  --background: #ffffff;
  border: solid 1px #e5e6ea;
  box-shadow: none;
  border-radius: 0;
  margin-inline: 0;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.colorDebug {
  --color: var(--ion-color-debug) !important;
  color: var(--ion-color-debug) !important;
}
.colorBgDebug {
  --background: var(--ion-color-debug) !important;
  background: var(--ion-color-debug) !important;
  h1,
  p,
  ion-icon,
  ion-text,
  ion-label {
    --color: var(--ion-color-light) !important;
    color: var(--ion-color-light) !important;
  }
}
.colorBoDebug {
  border-color: var(--ion-color-debug) !important;
}
ion-list.debugList {
  border: solid 2px var(--ion-color-debug);
}

// TODO: In zukunft wieder reverten
// BUGFIX für die datetime-picker dort wird bei klick nicht der picker dargestellt sondern nur der backdrop
div.ion-page:not(:has(> mits-header)) {
  display: contents;
}

ion-content {
  --background: var(--ion-color-medium);
}

ion-footer {
  background-color: var(--ion-color-light);
}

ion-alert {
  text-align: center !important;
  color: var(--ion-color-tertiary) !important;
  font-size: large !important;
}

.clickable {
  cursor: pointer;
}

/** Image-Drawing Component */
image-drawing {
  .toolbar {
    justify-content: center !important;

    .tools {
      padding: 10px !important;
    }

    .buttons {
      margin: 0px !important;
      flex-direction: row !important;
    }
  }
}

ion-label {
  --color: var(--ion-color-tertiary-shade) !important;
}

// Default item-devider has no end margin if something is in the end slot
ion-item-divider {
  & > ion-icon[slot='end'] {
    margin-right: 16px;
  }

  &.clickable {
    cursor: pointer;
    transition: 0.1s background ease-in-out;

    &:hover {
      --background: rgb(245, 245, 245);
    }
  }
}

.element-container {
  display: flex;
  flex-flow: column;
}

// Wenn mehrere Badges am ende eines Items im Slot dargestellt werden sollen
// <div slot="end" class="badgesSlot"><mits-badge>...</mits-badge>...</div>
.badgesSlot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  &.slotStart {
    justify-content: flex-start;
  }
}

// Wenn ein Item welcher detail und button ist als button dargestellt werden soll
.roundedItemButton {
  border-radius: 4px;

  &.withMargin {
    margin: 0px 8px 2px 8px;
  }
}

// Wenn ein Item alle Button in voller größe darstellen soll (wie sliding buttons)
.fullSizeButtonItem {
  &.startSlot {
    --inner-padding-start: 80px;

    &::part(native) {
      padding-left: 0px;
    }

    ion-button {
      position: absolute;
    }
  }
  &.endSlot {
    --inner-padding-end: 0px !important;
  }

  ion-button {
    height: 100% !important;
    margin: 0px !important;
    min-width: 60px;
  }
}

// Suche für z.B. cars oder Machines welche in einer eigenen Card liegt
.cardSearch {
  & > ion-card-content {
    & > input {
      border: none;
      border-radius: 3px;
      height: 36px;
      outline: none;
      width: 100%;
      color: var(--ion-color-primary);
    }
    & > ion-button {
      position: absolute;
      height: 36px;
      top: 8px;
      right: 16px;
    }
  }
}

.position-modal {
  --width: 400px !important;
  --height: 410px !important;
  --max-height: 100% !important;
}

.finish-wizard-modal {
  --width: 700px !important;
  --height: 900px !important;
  --max-height: 100% !important;
}

.order-writeable-checklist-modal {
  --width: 700px !important;
  --height: 900px !important;
  --max-height: 100% !important;
}

.mits-options-alert {
  --width: 400px !important;
  --height: 470px !important;
  --max-height: 100% !important;
}
.mits-options-alert-small {
  --width: 400px !important;
  --height: 270px !important;
  --max-height: 100% !important;
}

/** Needed for scanner component*/
video {
  position: relative;
  min-width: 300px !important;
  min-height: 600px !important;
}

ion-button.hasMitsIcon {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 8px;
  --padding-bottom: 8px;
}

// ion-accordion on template-page
ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      font-size: 20px !important;
      color: #6d6d6e !important;
    }
  }
}

ion-accordion {
  margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(100% - 12px);
  margin: 16px auto;
}

mits-selectable {
  display: list-item;
}

.openLoadByOrder-modal {
  --width: 700px !important;
  --height: 900px !important;
  --max-height: 100% !important;
}

[fullSize='true'] mits-scanner-button {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.animatePulseOpacity {
  animation: pulseOpacity 2s linear infinite;
}

.animatePulseScale {
  animation: pulseScale 1.2s ease-in-out infinite;
}

.animateRotate {
  animation: rotate 2s linear infinite;
}

@keyframes pulseOpacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseScale {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Globale styles für Mits-form-* Komponenten */
mits-form-date,
mits-form-object-select,
mits-form-object-selection,
mits-form-select{
  display: block;
  &.ng-valid {
    border-right: var(--ion-color-success) solid 6px !important;
  }

  &.ng-invalid {
    border-right: var(--ion-color-danger) solid 6px !important;
  }
}

/* Globale Regel für die Mits-Form Komponenten innerhalb ion-list */
ion-list.mits-form {
  padding: 0;

  /* Regel für alle Mits-Form-Komponenten mit der Klasse 'mits-form-item' in der Liste */
  > .mits-form-item > ion-item, {
    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, var(--ion-background-color-step-150, rgba(0, 0, 0, 0.13)))));
  }

  /* Regel für ng-valid und ng-invalid Klassen für alle Mits-Form-Komponenten */
  > .mits-form-item,  {
    display: block;
    &.ng-valid {
      border-right: var(--ion-color-success) solid 6px !important;
    }

    &.ng-invalid {
      border-right: var(--ion-color-danger) solid 6px !important;
    }
  }
}
/* Fix für mits-smart-table */
ion-searchbar {
  min-height: 60px;
}